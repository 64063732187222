.custom-disabled-input {
  pointer-events: none;
  background-color: #ffffff !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important; 
  border-radius: 3px; 
  opacity: 1 !important; 
  cursor: default; 
  cursor: text !important;
}

.custom-disabled-dropdown .p-dropdown {
  background-color: #fff;
  cursor: not-allowed;
  pointer-events: none;
  color: #495057;
  opacity: 1;
}

.custom-disabled-calendar .p-inputtext {
  background-color: #ffffff;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 1;
}

