.form-container {
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
  max-width: 800px; 
  margin: 40px auto;
  border: 1px solid #e0e0e0;
}

.form-container h3 {
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.field {
  margin-bottom: 20px;
}

.field label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.radio-group {
  display: flex;
  justify-content: start;
  gap: 20px;
  margin-top: 10px;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.radio-group label {
  margin-left: 5px;
}

.form-group {
  text-align: center;
}

/* Fix input field padding and focus */
input,
select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline-color: #4caf50;
}

/* Add shadow to button */
button {
  background-color: #f57c00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #e65100;
}

.error-message {
  color: red;
  font-size: 12px;
}

/* Fix spacing for dropdowns and inputs */
.select-container {
  margin-top: 5px;
}

.radio-group-container {
  margin-top: 10px;
  margin-bottom: 20px;
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 2px;
  cursor: pointer;
}

.form-check-label {
  font-size: 18px;
  cursor: pointer;
  margin-top: 2px;
}

.gap-5 {
  gap: 50px;
}


